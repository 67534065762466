
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainning-attendance-report',
  components: {
    Datatable,
    Multiselect,
  },

  data() {
    return {
      api_url: '',
      institutes: [] as any,
      pdfHeader: [] as any,
      tranches: [] as any,
      associations: [] as any,
      courses: [] as any,
      batches: [] as any,
      TpartnerData: [] as any,
      TranceData: [] as any,
      Tinstitute: '',
      TbatchData: '',
      TcourseData: '',
      TpartnerLabel: '',
      TranceLabel: '',
      TinstituteLabel: '',
      TbatchLabel: '',
      TcourseLabel: '',
      entityInfos: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourse: [],
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Registration No',
          key: 'reg_no',
          sortable: true,
          selected: true,
        },
        {
          name: 'Reference No',
          key: 'ref_no',
          sortable: true,
          selected: true,
        },
        {
          name: "Father's Name",
          key: 'fName',
          sortable: true,
        },
        {
          name: "Mother's Name",
          key: 'mName',
          sortable: true,
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_number',
          sortable: true,
        },

        {
          name: 'Total Class',
          key: 'total_class',
          sortable: true,
        },
        {
          name: 'Total Class Completed',
          key: 'total_class_completed',
          sortable: true,
          selected: true,
          width: '150px',
        },
        // {
        //   name: "Total Trainee Attendance",
        //   selected: true,
        //   key: "trainee_attendance",
        //   sortable: true,
        // },
        {
          name: 'Attendance Percentage (%)',
          key: 'percentage',
          sortable: true,
        },
        {
          name: 'Drop Out',
          key: 'dropOut',
          sortable: true,
        },
        {
          name: 'Present',
          key: 'present',
          sortable: true,
          selected: true,
        },
        {
          name: 'Absent',
          key: 'absent',
          sortable: true,
          selected: true,
        },
      ] as any,

      st: [] as any,
        CourseTypeData: [] as any,
      CourseListData: [] as any,
      TInstituteData: [] as any,
      TrancheLabel: [] as any,
      CourseLabel: [] as any,
      CourseListLabel: [] as any,
      TProgrameLabel: [] as any,
      TtypeLabel: [] as any,
      TlocationLabel: [] as any,
      districtInfos: [] as any,
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      CourseTypeInfos: [] as any,
      CourseListInfos: [] as any,
      InstituteInfos: [] as any,
      optionsTPartner: [] as any,
      optionsTranche: [] as any,
      loading: false,
      showCourseNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
      await this.getAssociation();
    await this.getTranche();
  },
  watch: {
    "TpartnerData": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/monitortraineeAttendanceExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'MonitorTraineeAttendance.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      let data = `${this.VUE_APP_API_URL}/api/report/monitortraineeAttendanceprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/monitortraineeAttendancePdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    batchLabel(id) {
      let selectedBatch = this.batches.filter((data) => data.id == id);

      this.TbatchLabel = selectedBatch[0].batch_number;
    },
    trancheLabelChange(id) {
      let selectedtranche = this.tranches.filter((data) => data.id == id);

      this.TranceLabel = selectedtranche[0].label;
    },
    async getCourseList(id) {
      this.load = true;
      let selectedInstitute = this.institutes.filter((data) => data.id == id);
      this.TinstituteLabel = selectedInstitute[0].short_name;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'course/list?entity_id=' +
        entity_id +
        '&tranche=' +
        this.TranceData +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
     async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.TpartnerData +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getBatchList(id) {
      this.load = true;
      let selectedCourse = this.courses.filter((data) => data.id == id);

      this.TcourseLabel = `${selectedCourse[0].code}| ${selectedCourse[0].course_name}| ${selectedCourse[0].month}| ${selectedCourse[0].hour}| ${selectedCourse[0].total_course_cost} `;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'batch/list?entity_id=' +
        entity_id +
        '&tranche_id=' +
        this.TranceData +
        '&course_info_id=' +
        this.TcourseData +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    resetData() {
      this.TranceLabel = '';
      this.TpartnerLabel = '';
      this.TinstituteLabel = '';
      this.TbatchLabel = '';
      this.TcourseLabel = '';
      this.TranceData = '';
      this.TpartnerData = '';
      this.TcourseData = '';
      this.TbatchData = '';
      this.Tinstitute = '';
      this.tableData = [] as any;
      this.st = [] as any;
      this.pdfHeader = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },
   async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
      async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async generateAttendanceReport() {
      if (this.TranceData) {
        this.load = true;
        await ApiService.get(
          'trainee/attendanceReport?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&institute=' +
          this.Tinstitute +
          '&course=' +
          this.TcourseData +
          '&batch=' +
          this.TbatchData
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.load = false;

            this.componentTableKey += 1;
          })
          .then((response) => {
            console.log(response);
            this.load = false;
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please Select Tranche & Training Partner.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },

    headerFilter() {
      this.showCourseNotice = true;
    },
    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  computed: {},
});
